@font-face {
  font-family: 'Graphik Web';
  src: url(./fonts/GraphikWeb/Graphik-Bold-Web.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal
}

.Graphik-Bold-Web {
  font-family: 'Graphik Web';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal
}

@font-face {
  font-family: 'Graphik Web';
  src: url(./fonts/GraphikWeb/Graphik-Semibold-Web.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal
}

.Graphik-Semibold-Web {
  font-family: 'Graphik Web';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal
}

@font-face {
  font-family: 'Graphik Web';
  src: url(./fonts/GraphikWeb/Graphik-Medium-Web.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal
}

.Graphik-Medium-Web {
  font-family: 'Graphik Web';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal
}


@font-face {
  font-family: 'Graphik Web';
  src: url(./fonts/GraphikWeb/Graphik-Regular-Web.woff2) format('woff2');
  
  font-weight: 400;
  font-style: normal;
  font-stretch: normal
}

.Graphik-Regular-Web {
  font-family: 'Graphik Web';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal
}

@font-face {
  font-family: 'Graphik Web';
  src: url(./fonts/GraphikWeb/Graphik-Light-Web.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal
}

.Graphik-Light-Web {
  font-family: 'Graphik Web';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal
}

body {
  background-color: #fafbfb;

}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
